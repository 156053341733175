import React from "react"
import { graphql } from "gatsby"
import Layout from "../../../components/Layout"
import "../../../styles/odooServices.scss"
import "../../../styles/services.scss"
import "../../../styles/caseStudy.scss"

import News from "../../../components/News"
import ProductTopSection from "../../../components/ProductTopSelection"
import WhyChooseVisiomateforD365 from "../../../components/WhyChooseVisiomateforD365"
import Wethoughtyoumighthavequestions from "../../../components/Wethoughtyoumighthavequestions"
import CaseStudeiesSimplified from "../../../components/CaseStudeiesSimplified"

import ImageWithTextSection from "../../../components/ImageWithTextSection"

const ClassManagement = ({ data }) => {
    return (
        <div className="CaseStudeies">
            <Layout pageTitle={"Visiomate - Class Management Solution"}
            descrip = {"Streamline your classroom with our powerful class management solution. Simplify attendance, assignments, grading, and communication. Try it today!"}>
                <ProductTopSection
                    headerData={{
                        title:
                            "Revolutionize Your Processes with Our Comprehensive Class Management Solution",
                        peragraph:
                            "Centralizing tasks and fostering collaboration, our Class Management Solution optimizes education operations for efficient learning outcomes and enriched student experiences.",
                        // image: headerImage,
                    }}
                    data={data}
                />
                <WhyChooseVisiomateforD365
                    data={{
                        title: "Why Choose Visiomate's Class Management Solution?",
                        headings: [
                            {
                                heading: "Streamlined Administrative Tasks",
                                description:
                                    "Automates processes such as scheduling, attendance tracking, grading, and resource allocation, reducing administrative burden and improving efficiency.",
                            },
                            {
                                heading: "Improved Communication",
                                description:
                                    "Provides centralized communication channels for teachers, students, and parents, facilitating quick and effective communication, feedback, and updates.",
                            },
                            {
                                heading: "Enhanced Collaboration",
                                description:
                                    "Offers tools for collaborative learning and group projects, fostering teamwork, knowledge sharing, and peer interaction.",
                            },
                            {
                                heading: "Efficient Learning Outcomes",
                                description:
                                    "Supports personalized learning pathways, progress tracking, and analytics to identify areas for improvement, leading to more effective teaching and learning.",
                            },
                            {
                                heading: "Enriched Student Experiences",
                                description:
                                    "Enhances engagement through interactive learning materials, multimedia resources, and gamification elements, creating a more dynamic and immersive learning environment.",
                            },
                            {
                                heading: "Data-Driven Decision Making",
                                description:
                                    "Enabling educators and administrators to make informed decisions based on accurate data, leading to continuous improvement in teaching strategies and student performance.",
                            },
                        ],
                    }}
                    dataFormImages={data}
                />
                <ImageWithTextSection
                    caseStudyData={{
                        data: [
                            {
                                heading: "Dashboards",
                                description:
                                    " Graphs, charts, and other visual elements make complex data more comprehensible, enabling users to quickly grasp trends, patterns, and outliers.",
                                // image: img1,
                            },
                            {
                                heading: "Dynamic Admission Form Builder",
                                description:
                                    "Our CMS features a dynamic admission form builder akin to Google Forms, enabling creation of sections, pages, diverse question types, and conditional logic for both required and optional responses.",
                                // image: img2,
                            },
                            {
                                heading: "Manual or Auto Generated Timetable",
                                description:
                                    "The timetable feature allow users to craeted timetable on a single click. The Auto generated timetable uses the availability and preference set for the teachers and rooms to build the suitable timtable.",
                                // image: img3,
                            },
                            {
                                heading: "Multiple Views of Timetable",
                                description:
                                    "The timetable be viewed from the perspective of a single teacher, subject and room. Additionally, the timetable can be viewed for all teacher, all subjects and all rooms as well.",
                                // image: img4,
                            },
                            {
                                heading: "Manage Session, Programs, Classes and Sub-Classes",
                                description:
                                    "The education management system allows users to create multiple sessions, programs and classes in the hierarchy.",
                                // image: img5,
                            },
                            {
                                heading: "8 Steps Detailed Class Creation",
                                description:
                                    "The education management system provides a wide range of attributes for a class. The class attributes are grouped into basic information, registartion and seats, schedule, billing and payment, terms and condition and application form.",
                                // image: img6,
                            },
                            {
                                heading: "Separate Portal for Teachers, Students and Parents",
                                description:
                                    "The education management system offers separate portal for teachers, students and parents. Teachers and students can use there portal to moniter and participate in class activity, communicate with other students or with teacher.",
                                // image: img7,
                            },
                            {
                                heading: "Manage Teachers, Students, Subjects and Enrollments",
                                description:
                                    "The education management system provides ability to manage data related to teachers, students, subjects and enrollments.",
                                // image: img8,
                            },
                            {
                                heading: "Manage Multiple Locations",
                                description:
                                    "The education management system provides ability create multiple location inside a single school which helps user get specific reports.",
                                // image: img9,
                            },
                            {
                                heading: "Up-front Complete Planned Payments Information",
                                description:
                                    "The education management system provides ability to view all the planned invoices for the enrolled class.",
                                // image: img10,
                            },
                        ],
                    }}
                    dataFormImages={data}
                />
                <CaseStudeiesSimplified
                    TextData={{
                        title: "Simplified Steps to get you Started ",
                        Data: [
                            {
                                peragraph: "Define Requirements",
                                list: [
                                    "Clearly outline your business objectives and identify the specific challenges or needs that the software solution should address.",
                                    "List down the essential features and functionalities required.",
                                ],
                            },
                            {
                                peragraph: "Demo and Evaluation",
                                list: [
                                    "Schedule product demonstrations. ",
                                    "Evaluate the software's usability, features, and performance. ",
                                    "Engage key stakeholders in the evaluation process.",
                                ],
                            },
                            {
                                peragraph: "Scalability and Integration",
                                list: [
                                    "Identify the potential integrations required by the customer.",
                                    "Plan the implementation of identified integrations.",
                                ],
                            },
                            {
                                peragraph: "UAT & User Feedback",
                                list: [
                                    "UAT period allows customer to assess the system performance and suitability for the specific business needs.",
                                    "Customer’s insights can provide valuable perspectives on usability, functionality mismatch.",
                                ],
                            },
                            {
                                peragraph: "User Training",
                                list: [
                                    " User training is a critical component of successfully implementing the software solution.",
                                    "Proper training ensures that users can effectively and efficiently utilize the software, maximizing its benefits.",
                                ],
                            },
                            {
                                peragraph: "Post-Implementation Support",
                                list: [
                                    "Provide ongoing support for users.",
                                    "Address any post-implementation issues.",
                                    "Evaluate and implement system enhancements based on feedback.",
                                ],
                            },
                        ],
                    }}
                />
                <Wethoughtyoumighthavequestions
                    data={[
                        {
                            question:
                                "What is a Class Management Application? ",
                            answer:
                                "The Class Management Application is a software tool designed to facilitate the administration, organization, and delivery of educational classes, courses, or training sessions.",
                        },
                        {
                            question:
                                "How does a Class Management Application benefit educational institutions or training centers?",
                            answer:
                                "It helps institutions streamline class scheduling, automate administrative tasks, improve student engagement, monitor attendance and performance, enhance communication between instructors and students, and analyze class data for continuous improvement.",
                        },
                        {
                            question:
                                "Can a Class Management Application handle both in-person and online classes?",
                            answer:
                                "Yes, many Class Management Applications offer flexibility to manage both in-person and online classes, allowing institutions to adapt to various learning modalities and delivery methods.",
                        },
                        {
                            question:
                                "What role does student registration play in a Class Management Application?",
                            answer:
                                "This application have a special feature of google forms like form creation. Admin can create multiple forms to fullfil different needs, regestration form can be created and used for a class registration. Student can register in a class by filling the form.",
                        },
                        {
                            question:
                                "How does attendance tracking work in a Class Management Application?",
                            answer:
                                "Attendance tracking features enable instructors to record student attendance, monitor punctuality, track absences, and generate attendance reports for monitoring student participation and compliance.",
                        },
                        {
                            question:
                                "How does communication between instructors and students work in a Class Management Application?",
                            answer:
                                "Communication tools such as announcements, messaging systems, discussion forums, and email integration facilitate effective communication between instructors and students, fostering collaboration and support outside of class sessions.",
                        },
                        {
                          question:
                              "Can a Class Management Application handle grading and assessment?",
                          answer:
                              "Yes, many Class Management Applications offer tools for instructors to create and administer quizzes, assignments, and exams, grade student submissions, provide feedback, and calculate final grades.",
                      },
                    ]}
                    title="We thought you might have questions"
                />
                <News isAbout={true} career={true} />
            </Layout>
        </div>
    )
}

export default ClassManagement
export const query = graphql`
  query ClassManagementImages {
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    HeaderImg: file(relativePath: { eq: "EMS/ems_top_header.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markFlag: file(
      relativePath: { eq: "4879902_check_flag_mark_ok_icon_1_copy.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgImages: file(relativePath: { eq: "prodbg-01.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img1: file(relativePath: { eq: "EMS/1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img2: file(relativePath: { eq: "EMS/2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img3: file(relativePath: { eq: "EMS/3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img4: file(relativePath: { eq: "EMS/4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img5: file(relativePath: { eq: "EMS/5.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img6: file(relativePath: { eq: "EMS/6.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img7: file(relativePath: { eq: "EMS/7.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img8: file(relativePath: { eq: "EMS/8.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img9: file(relativePath: { eq: "EMS/9.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    img10: file(relativePath: { eq: "EMS/10.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
